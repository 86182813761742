<template>
    <div ref="eventWrapper">
        <div class="content-viewtitle">本保單所有通知</div>
        <div
            v-if="tableShowData.length > 0"
            class="content-tips"
            v-text="contentTips"
        />
        <TextboxSearch
            :isLoading="isLoading"
            :placeholder="'搜尋通知內容關鍵字'"
            :dataList="tableShowData"
            :searchConfigs="searchConfigs"
            v-model:modelValue="searchKeyword"
            @searched-data="setSearchedData"
        ></TextboxSearch>
        <Table
            v-if="tableShowData.length > 0 || isLoading"
            :isLoading="isLoading"
            :columnConfig="columnConfig"
            :rows="searchedData"
            class="event-table"
        >
            <template
                v-slot:mobile-header
                v-if="
                    tableShowData.some((r) => r.isChecked === false) &&
                    this.$hasPermission('completeNotice')
                "
            >
                <Checkbox
                    name="notificationAll"
                    value="all"
                    label="全選"
                    v-model="selectedNoticesAll"
                />
            </template>
            <template v-slot:complete="{ isHeader, row }">
                <div v-if="isHeader">
                    <Checkbox
                        v-if="tableShowData.some((r) => r.isChecked === false)"
                        name="notificationAll"
                        value="all"
                        v-model="selectedNoticesAll"
                    />
                </div>
                <div v-else>
                    <Checkbox
                        v-if="row.checkedAt === null"
                        name="notification"
                        :value="row.notifyId"
                        v-model="selectedNotices"
                    />
                    <div v-else class="success-icon" />
                </div>
            </template>
            <template v-slot:status="{ isHeader, row }">
                <div v-if="!isHeader">
                    <span
                        v-if="row.isChecked === false"
                        class="red-word"
                        v-text="'未完成'"
                    />
                    <span
                        v-if="row.isChecked === true"
                        class="green-word"
                        v-text="'已完成'"
                    />
                </div>
            </template>
            <template v-slot:title="{ isHeader, row }">
                <template v-if="!isHeader">
                    {{ row?.title }}{{ row?.subject ? '-' + row?.subject : ''
                    }}{{ row?.description ? `(${row.description})` : '' }}
                </template>
            </template>
            <template v-slot:attached="{ isHeader, row }">
                <template v-if="!isHeader">
                    <template v-if="row.docInfos.length > 0">
                        <div
                            v-if="row.docInfos.length > 1"
                            class="appendix plural"
                            @click="handlerDocModal(row.docInfos)"
                        >
                            <span>查看所有附件</span>
                            <div class="download-content-icon" />
                        </div>
                        <template v-else>
                            <a
                                v-for="(doc, index) in row.docInfos"
                                :key="index"
                                :href="doc.link"
                                class="appendix"
                            >
                                <span>
                                    {{ doc.name }}
                                </span>
                                <div class="download-link-icon" />
                            </a>
                        </template>
                    </template>

                    <span
                        v-else
                        class="no-download-link"
                        v-text="'無附件(或查閱影像檔)'"
                    />
                </template>
            </template>
        </Table>
        <div v-else class="no-notice">尚無任何通知</div>
    </div>
    <InsuranceDocDownloadModal
        title="照會附件檔"
        v-model="showDocModal"
        :docList="docList"
    />
</template>

<script>
import _ from 'lodash'
import Table from '@/components/Table.vue'
import TextboxSearch from '@/components/TextboxSearch.vue'
import Checkbox from '@/components/Checkbox.vue'
import {
    insuranceEventColumns,
    insuranceEventSearch
} from '@/assets/javascripts/tia/setting'
import smoothscroll from 'smoothscroll-polyfill'
import InsuranceDocDownloadModal from '@/containers/tia/InsuranceDocDownloadModal.vue'

export default {
    name: 'InsuranceEventTable',
    emits: ['update:selected', 'is-from-complete-modal'],
    components: {
        Table,
        Checkbox,
        InsuranceDocDownloadModal,
        TextboxSearch
    },
    props: {
        dataList: {
            type: Object,
            default: function () {
                return {}
            }
        },
        selected: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isLoading: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        isViewAll: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        noticeId: {
            type: [Number, String],
            default: function () {
                return null
            }
        },
        isFromCompleteModal: {
            type: Boolean,
            default: function () {
                return false
            }
        }
    },
    methods: {
        setEachOptionChecked: function (isChecked) {
            const values = this.searchedData
                .filter((row) => {
                    if (row.checkedAt === null) {
                        return row.notifyId
                    }
                })
                .map((filtered) => filtered.notifyId)
            values.forEach((value) => {
                this.selectedNotices[value] = isChecked
            })
        },
        handlerDocModal: function (docs) {
            this.showDocModal = true
            this.docList = docs
        },
        setSearchedData(results) {
            this.searchedData = results
        }
    },
    computed: {
        selectedNotices: {
            get() {
                return this.selected
            },
            set(val) {
                this.$emit('update:selected', val)
            }
        },
        selectedNoticesAll: {
            get() {
                // 根據目前篩選出的 data 做全選判斷
                const searchDataIds = this.searchedData.map(
                    (data) => data.notifyId
                )
                const values = Object.entries(this.selectedNotices)
                    .filter(([key]) => searchDataIds.includes(key))
                    .map(([, value]) => value)
                const shouldChecked =
                    values.length > 0 && values.every((checked) => checked)
                return { all: shouldChecked }
            },
            set(val) {
                this.setEachOptionChecked(val.all)
            }
        },
        columnConfig: function () {
            const columns = _.cloneDeep(insuranceEventColumns)
            if (!this.$hasPermission('completeNotice')) {
                delete columns?.complete
                return columns
            } else {
                return columns
            }
        },
        tableShowData: function () {
            if (!this.dataList.insServices) return []
            let insServices = this.dataList.insServices

            // if (!this.isViewAll)
            //     insServices = insServices.filter(
            //         (service) => service.isChecked === false
            //     )

            return insServices.map((row) => {
                if (this.noticeId == row.notifyId)
                    row.className = { highlight: true }
                return row
            })
        },
        contentTips: function () {
            return '如未看到待處理通知附件內容，請檢查影像檔區塊是否有相關檔案。'
        },
        searchConfigs: function () {
            return insuranceEventSearch
        }
    },
    watch: {
        tableShowData: {
            // 設置初始data
            handler() {
                this.searchedData = this.tableShowData
                this.setEachOptionChecked(false)
            },
            immediate: true
        }
    },
    data() {
        return {
            showDocModal: false,
            docList: [],
            searchedData: [],
            searchKeyword: ''
        }
    },
    updated() {
        this.$nextTick(() => {
            const eventWrapper = this.$refs.eventWrapper
            let highlightItem = eventWrapper.querySelector('.highlight')
            if (highlightItem) {
                // use polyfill to fix safari not scroll smooth
                smoothscroll.polyfill()
                highlightItem.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center'
                })
            }
        })
    },
    activated() {
        // 保險明細頁面中通知內容判斷是否要重置data
        if (!this.isFromCompleteModal) {
            this.searchKeyword = ''
            this.searchedData = this.tableShowData
            this.setEachOptionChecked(false)
        }
        this.$emit('is-from-complete-modal', false)
    }
}
</script>

<style lang="scss" scoped>
.viewtitle {
    @media screen and (max-width: 576px) {
        :deep(.main-header-word) {
            font-size: 20px;
        }
    }
}
.no-notice {
    color: $ninth-grey;
    text-align: center;
}

.event-table {
    width: 100%;

    :deep(.table) {
        max-height: none;

        &.loading {
            .table-body {
                .row-container:nth-of-type(3) ~ .row-container {
                    display: none;
                }
            }
            @media screen and (max-width: 576px) {
                //卡片loading樣式
                .table-body {
                    .row-container {
                        .row.body.loading {
                            .status {
                                width: 100px;
                                &.cell {
                                    flex-wrap: nowrap;
                                    margin-bottom: -3px;
                                }
                            }
                            .complete {
                                &.cell {
                                    flex-wrap: nowrap;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

:deep(.highlight) {
    &,
    &.fadeIn,
    &.transparent {
        border: 3px solid;
        border-color: $dashboard-yellow;
        animation: blink-animation 0.7s ease-in-out 0s 5 alternate-reverse;
        @keyframes blink-animation {
            to {
                border-color: $primary-white;
            }
        }
    }
}

:deep(.download-link-icon),
:deep(.download-content-icon) {
    display: block;
    max-width: 7.5em;
    height: 20px;
    padding-left: 20px;
    margin-right: 5px;
    background-position: left;
    text-align: left;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    cursor: pointer;
}
:deep(.download-link-icon) {
    background-size: 25px 25px;
    background-position: -2px -2px;
}
:deep(.no-download-link) {
    color: $ninth-grey;
}

:deep(.complete) {
    padding: 0 !important;

    .success-icon {
        position: relative;
        width: 16px;
        height: 16px;
        border: 2px $primary-white solid;
        background-color: $primary-white;
    }
}

:deep(.status) {
    padding: 0 !important;

    .red-word {
        color: $primary-red;
        word-break: keep-all;
    }

    .green-word {
        position: relative;
        color: $primary-green;
        word-break: keep-all;
    }
}
:deep(.complete) {
    .red-word {
        display: none;
    }
}
:deep(.attached) {
    .appendix {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
        &.plural {
            cursor: pointer;
            span {
                color: $fourth-blue;
            }
        }
    }
}
.table-wrapper {
    :deep(.mobile-header) {
        padding: 0 18px;
    }

    :deep(.table-container.card) {
        @media screen and (max-width: 576px) {
            .table .mobile-header {
                margin-bottom: 0px;
            }
        }
    }

    :deep(.row-container .row.body) {
        &.highlight {
            border-top: $dashboard-yellow 3px solid;
            padding: 0 17px;
        }
        @media screen and (max-width: 576px) {
            .cell {
                &::before {
                    margin-bottom: 5px;
                }
            }
            &.highlight {
                padding: 8px 12px;
            }
            .status {
                order: -1;
                &.cell {
                    justify-content: flex-start;
                    width: 30px;
                    margin-bottom: 10px;
                    &::before {
                        display: none;
                    }
                }
            }
            .complete {
                order: -1;
                &.cell {
                    justify-content: flex-start;
                    width: fit-content;
                    margin-left: -5px;
                    margin-bottom: 10px;
                    div {
                        display: flex;
                        .checkbox-container {
                            width: 20px;
                        }
                        .red-word {
                            display: inline-block;
                            color: $primary-red;
                            word-break: keep-all;
                            margin-left: 5px;
                        }
                    }
                }
            }
            .date {
                &.cell {
                    margin-left: auto;
                    justify-content: flex-end;
                    width: 50%;
                    &::before {
                        display: none;
                    }
                }
            }
            .title {
                &.cell {
                    &::before {
                        text-align: left;
                        width: 100%;
                    }
                }
            }
            .attached {
                &.cell {
                    padding-top: 10px;
                    border-top: 1px dashed $fourth-grey;
                    &::before {
                        text-align: left;
                        width: 100%;
                    }
                    a {
                        width: 100%;
                    }
                }
                .appendix {
                    align-items: center;
                }
            }
        }
    }
}

.content-viewtitle {
    margin: 0;
    color: $sixth-black;
    font-size: 18px;
    margin-bottom: 5px;
    font-weight: 500;
}
.content-tips {
    color: $sixth-black;
    font-size: 14px;
    margin-bottom: 10px;
}
</style>
